var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { setParams } from '../../utils/setParams';
import { StyledAsyncPaginate } from './styles';
var AsyncSelect = function (_a) {
    var name = _a.name, label = _a.label, helperText = _a.helperText, _b = _a.isMulti, isMulti = _b === void 0 ? false : _b, _c = _a.disable, disable = _c === void 0 ? false : _c, _d = _a.required, required = _d === void 0 ? false : _d, fetchData = _a.fetchData, placeholder = _a.placeholder, _e = _a.isSearchable, isSearchable = _e === void 0 ? true : _e, getOptionLabel = _a.getOptionLabel, getOptionValue = _a.getOptionValue, _f = _a.sx, sx = _f === void 0 ? {} : _f, _g = _a.customContainerStyles, customContainerStyles = _g === void 0 ? {} : _g, _h = _a.customPlaceHolderStyles, customPlaceHolderStyles = _h === void 0 ? {} : _h, value = _a.value, onChangeProp = _a.onChange, _j = _a.limit, limit = _j === void 0 ? 10 : _j, other = __rest(_a, ["name", "label", "helperText", "isMulti", "disable", "required", "fetchData", "placeholder", "isSearchable", "getOptionLabel", "getOptionValue", "sx", "customContainerStyles", "customPlaceHolderStyles", "value", "onChange", "limit"]);
    var _k = useState(0), page = _k[0], setPage = _k[1];
    var _l = useState(null), filterName = _l[0], setFilterName = _l[1];
    var loadOptions = function (searchQuery) { return __awaiter(void 0, void 0, void 0, function () {
        var isSearch, params, data, docs, meta, hasMore;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    isSearch = !!searchQuery;
                    if (searchQuery) {
                        setFilterName(searchQuery);
                        setPage(0);
                    }
                    params = setParams({ page: page, limit: limit, search: searchQuery || '' });
                    return [4 /*yield*/, fetchData(params)];
                case 1:
                    data = _a.sent();
                    docs = data.docs, meta = data.meta;
                    hasMore = meta.hasMore;
                    if (!isSearch)
                        setPage(function (prev) { return (hasMore ? prev + 1 : page); });
                    return [2 /*return*/, {
                            options: docs,
                            hasMore: hasMore,
                            additional: {
                                page: isSearch ? 0 : page,
                            },
                        }];
            }
        });
    }); };
    return (_jsx(AsyncPaginate, __assign({ onChange: function (e) {
            if (onChangeProp) {
                onChangeProp(e);
            }
        }, value: value, isMulti: isMulti, additional: {
            page: 1,
        }, isDisabled: disable, loadOptions: loadOptions, getOptionLabel: function (option) { return getOptionLabel(option); }, getOptionValue: function (option) { return getOptionValue(option); }, placeholder: placeholder || 'select item', isSearchable: isSearchable, required: required, styles: StyledAsyncPaginate(sx, customContainerStyles, customPlaceHolderStyles) }, other)));
};
export default AsyncSelect;
